<template>
  <!-- Dynamically choose a component based on the post type -->
  <component :is="selectedComponent" :id="id" />
</template>

<script>
// Import your various blog post layout components
import StandardBlogPost from './BlogPost.vue'
import BlogPhoto1 from './BlogPhoto1.vue'
import BlogTravel1 from './BlogTravel1.vue'
//import TravelBlogPost from './TravelBlogPost.vue'

export default {
name: 'BlogPostWrapper',

  props: {
    	id: {
	type: String, // or Number, depending on your use case
	required: true
	}
  },
data() {
    return {
	type: 'base',
	}},
    mounted() {


	console.log("Mounted:",this.id)



  fetch(`/posts/${this.id}.json`)
	   .then(response => response.json())
	    .then(data => {
		console.log("Data",data)
		this.post = data

		if ("type" in this.post)
		    this.type = this.post.type
	    })
     },
  computed: {
    selectedComponent() {
      // Check your meta field and return the corresponding component
      switch(this.type) {
        case 'photo1':
          return BlogPhoto1;
        case 'travel1':
          return BlogTravel1;
	  
        // Add more cases as you add new layouts
        default:
          return StandardBlogPost;
      }
    }
  }
}
</script>
