<template>
  <PageHeader></PageHeader>
  <div class="page-container">
    <div class="content-container">
      <h1 class="post-title">{{ post.title }}</h1>
      <div class="blog-post">
        <!-- Photo Carousel Section -->
        <div class="carousel-container">
          <button @click="prevImage" class="carousel-button">Prev</button>
          <div class="carousel-wrapper">
            <img
              :src="photos[currentIndex]"
              alt="Carousel Image"
              class="carousel-image"
              @load="detectOrientation"
              :class="{ portrait: isPortrait }"
            />
          </div>
          <button @click="nextImage" class="carousel-button">Next</button>
        </div>
      </div>

    <div class="content-container">
      <div class="post-content" v-html="post.content">
	</div>      
    </div>






    </div>
  </div>
</template>

<script>
import PageHeader from './PageHeader.vue';
export default {
  name: 'BlogPost',
  components: {
    PageHeader
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      post: {},
      currentIndex: 0,
      isPortrait: false
    }
  },
  computed: {
    photos() {
      return this.post && this.post.photos ? this.post.photos : [];
    }
  },
  methods: {
    detectOrientation(event) {
      const img = event.target;
      this.isPortrait = img.naturalHeight > img.naturalWidth;
    },
    nextImage() {
      if (!this.photos.length) return;
      this.currentIndex = (this.currentIndex + 1) % this.photos.length;
    },
    prevImage() {
      if (!this.photos.length) return;
      this.currentIndex =
        (this.currentIndex - 1 + this.photos.length) % this.photos.length;
    }
  },
  mounted() {
    console.log("Mounted:", this.id);
    fetch(`/posts/${this.id}.json`)
      .then(response => response.json())
      .then(data => {
        console.log("Data", data);
        this.post = data;
        console.log(this.post.photos);
        fetch(`/posts/${this.id}.html`)
          .then(response => response.text())
          .then(data => {
            console.log(data);
            this.post.content = data;
          });
      });
  }
}
</script>

<style scoped>
/* Page container mimics min-h-screen, background, and vertical padding */
.page-container {
  min-height: 100vh;
  background-color: #ffffff;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Content container centers the content with a max width */
.content-container {
  position: relative;
  padding: 0.75rem 0;
  margin: 0 auto;
  max-width: 56rem; /* Equivalent to Tailwind's max-w-4xl  56*/
}




/* Carousel container: horizontal flex layout with gap */
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/* Carousel button styles: background with opacity and hover effects */
.carousel-button {
  background-color: rgba(55, 65, 81, 0.25);
  color: #ffffff;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.carousel-button:hover {
  background-color: rgba(55, 65, 81, 0.75);
}

/* Carousel wrapper: fixed height and centered image 400 */
.carousel-wrapper {
  width: 100%;
  background-color: #ffffff;
  height: 600px; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 56rem;
}

/* Image styling: make image fill container while preserving aspect ratio */
.carousel-image {
  width: auto; /*100%*/
  height: 100%;
  object-fit: contain;
/*border-radius: 1.5rem;*/ /* Similar to Tailwind's rounded-3xl */
border-radius: 25px;
padding: 20px;
  object-position: center;
}

/* Adjustments for portrait images */
.carousel-image.portrait {
  object-fit: contain;
  width: auto;
  height: 100%;
  object-position: center;
  display: block;
  margin: auto;
}

/* Title styling: font size, weight, color, and centered text */
.post-title {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #2D3748;
  border-bottom: 2px solid #E2E8F0;
  padding-bottom: 0.5rem;
}

::v-deep .post-h1 {
  font-size: 2.25rem;
  font-weight: 600;
  color: #1D4ED8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.content-container {
 
}


::v-deep .post-content {
  max-width: 100%;
  color: #4A5568; 
  margin-bottom: 2rem;
  font-family: 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  /* Adding a bit more breathing room */
  padding: 0 1rem;
}

::v-deep .post-content h1 {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #2D3748;
  border-bottom: 2px solid #E2E8F0;
  padding-bottom: 0.5rem;
}

::v-deep .post-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #2D3748;
  border-left: 4px solid #E2E8F0;
  padding-left: 0.5rem;
}

::v-deep .post-content h3 {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  color: #2D3748;
}

::v-deep .post-content p {
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

::v-deep .post-content ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

::v-deep .post-content li {
  margin-bottom: 0.5rem;
}

/* Link styling for a subtle glowing underline effect */
::v-deep .post-content a {
  color: #3182CE;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

::v-deep .post-content a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #3182CE;
  opacity: 0.5;
  transition: opacity 0.3s ease, height 0.3s ease;
}

::v-deep .post-content a:hover {
  color: #2B6CB0;
}

::v-deep .post-content a:hover::after {
  opacity: 1;
  height: 2px;
}




/*
@media (max-width: 600px) {


  .post-content h1 {
    font-size: 1.8rem;
  }

}
*/


</style>
