<template>
  <PageHeader></PageHeader>
  <div class="min-h-screen bg-gray-100 py-6 sm:py-2">
    <div class="relative sm:max-w-4xl sm:mx-auto">
      <div class="px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
        <!-- Page Title -->
        <h1 class="text-4xl font-semibold text-orange-700 mb-8">Blog</h1>

        <!-- Blog Posts Loop -->
        <div
          v-for="post in posts"
          :key="post.id"
          class="border rounded-md p-6 space-y-4 mb-8 flex flex-wrap"
        >
          <!-- Image -->
          <a :href="'/blog/' + post.id" class="lg:w-2/4">
            <img
              :src="post.image"
              :alt="post.imageDescription"
              class="w-full h-auto object-cover object-top rounded-md mb-4"
            />
          </a>

          <!-- Content Area -->
          <div class="flex-1 space-y-2 px-4">
            <!-- Post Info -->
            <h2 class="text-2xl font-bold text-gray-800">{{ post.title }}</h2>
            <p class="text-sm text-gray-600">
              By {{ post.author }} - {{ post.date }}
            </p>
            <!-- Excerpt -->
            <p class="text-gray-700">{{ post.excerpt }}</p>
            <!-- Read More Link -->
            <a :href="'/blog/' + post.id" class="text-blue-500 hover:underline">
              Read More
            </a>
          </div>
        </div>


        <!-- Pagination Controls -->
        <div class="flex justify-between mt-8">
          <!-- Newer Posts: Only show if we are not on the default page -->
          <div>
 <template v-if="page > 0">
	      <button class="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-blue font-bold py-2 px-4 rounded" @click="newerLink">Newer Posts</button>
</template>

          </div>
          <!-- Older Posts: Only show if we haven't reached the max page -->
          <div>
	    <template v-if="page > 1 || page == 0">
	      <button class="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-blue font-bold py-2 px-4 rounded" @click="olderLink">Older Posts</button>
	      </template>
              

	    
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'BlogListing',
  components: {
    PageHeader
  },
  data() {
    return {
      posts: [],
      // Hardcoded maximum page (update this value as needed)
      maxPage: 1,
    };
  },
props: {
    // Expecting the router to pass the query param as a prop
    page: {
      type: [Number],
      default: 0
    }
  },    
  computed: {
    // Compute currentPage based on the passed prop
    currentPage() {
      const pageNumber = parseInt(this.page, 10);
      // Return 0 (default listing) if invalid or out of bounds
      if (!pageNumber || pageNumber < 1 || pageNumber > this.maxPage) {
        return 0;
      }
      return pageNumber;
    }
     }, 
  methods: {
    loadPosts() {
      // Build the file path based on the currentPage value.
      let filePath = '/posts/blog_listing.json';
	if (this.currentPage > 0) {
	    
        filePath = `/posts/blog_listing_page_${this.currentPage}.json`;
      }
      fetch(filePath)
        .then(response => response.json())
        .then(data => {
          this.posts = data;
        })
        .catch(err => {
          console.error('Error fetching posts:', err);
          this.posts = [];
        });
    },
olderLink() {
	  // 
	  console.log("WTF")
	  console.log(this.page)
	  //this.$router.push(
    if (this.page == 0) {
	console.log("WTF 2",this.maxPage)
	return this.$router.push(`/blog/page/${this.maxPage}`)//.then(() => {this.$router.go(0);});
	
	}

	  
	  this.$router.push(`/blog/page/${this.page-1}`)//.then(() => {this.$router.go(0);});
	 
    },
      newerLink() {
	  const pageNumber = parseInt(this.page, 10) + 1;
	  if (pageNumber > this.maxPage)
	      return  this.$router.push(`/blog`)
	  
	  console.log("WTF",pageNumber,`/blog/page/${pageNumber}`)
	   this.$router.push(`/blog/page/${pageNumber}`)//.then(() => {this.$router.go(0);});

	  }
      
    
   
  },
 watch: {
    // When the "page" prop changes, reload the posts.
    page() {
      this.loadPosts();
    }
  },
  mounted() {
    this.loadPosts();
  }
};
</script>

<style scoped>
/* Add any additional component-specific styles here */
</style>
