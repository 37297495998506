<template>
  <div class="page-wrapper">
    <header class="page-header">
      <!-- Using router-link for navigation, or replace with <a href="/"> if not using Vue Router -->
      <router-link to="/" class="logo">How to Jeremy</router-link>
    </header>
    
    <div class="blog-container">
      <h1 class="title">{{title}}</h1>
            <h2 >{{date}}</h2>
      <!--
	  <canvas id="cityCanvas" width="300" height="150"></canvas>
	  -->
      <div
        v-for="(entry, index) in entries"
        :key="entry.id"
        :class="['entry', index % 2 === 0 ? 'entry--left' : 'entry--right']"
      >
        <div class="entry__image">
          <img :src="entry.image" :alt="entry.title" />
        </div>
        <div class="entry__content">
          <h2>{{ entry.title }}</h2>
          <p>{{ entry.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const query = `
[out:json];
relation["name"="Dublin"]["admin_level"="7"];
out geom;
`;


async function fetchCachedCoords() {
  const res = await fetch('/posts/dublin_coords.json');
  return await res.json();
}

async function fetchDublinOutline() {
  const response = await fetch('https://overpass-api.de/api/interpreter', {
    method: 'POST',
    body: query
  });
  const data = await response.json();
  return data;
}

function convertCoords(coords, width, height, bounds) {
  const [minLon, minLat, maxLon, maxLat] = bounds;

    return coords.map(coord => {
	
    const x = ((coord.lon - minLon) / (maxLon - minLon)) * width;
    const y = height - ((coord.lat - minLat) / (maxLat - minLat)) * height;
    return { x, y };
  });
}

function getBounds(coords) {
  let minLon = Infinity, minLat = Infinity, maxLon = -Infinity, maxLat = -Infinity;

  coords.forEach(coord => {
    minLon = Math.min(minLon, coord.lon);
    minLat = Math.min(minLat, coord.lat);
    maxLon = Math.max(maxLon, coord.lon);
    maxLat = Math.max(maxLat, coord.lat);
  });

  return [minLon, minLat, maxLon, maxLat];
}

async function animateCity() {
  const canvas = document.getElementById('cityCanvas');
    const ctx = canvas.getContext('2d');
    ctx.canvas.width  = window.innerWidth;
    ctx.canvas.height = window.innerHeight;    
  ctx.lineWidth = 2;
//    ctx.strokeStyle = "#4a90e2";
    //#FFAC1C
    //ctx.strokeStyle = '#ffac1c'

  ctx.strokeStyle = '#36454f'

   // const osmData = await fetchDublinOutline();
    const coords = await fetchCachedCoords();
 // const coords = osmData.elements[0].members.flatMap(member => member.geometry);

   const validCoords = coords.filter(coord => coord && coord.lon !== undefined && coord.lat !== undefined);
  console.log(validCoords)
  
    const bounds = getBounds(validCoords);

  const pixelCoords = convertCoords(validCoords, canvas.width, canvas.height, bounds);

  let progress = 0;
  const totalPoints = pixelCoords.length;

    function draw2() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.beginPath();

  if (pixelCoords.length > 0) {
    ctx.moveTo(pixelCoords[0].x, pixelCoords[0].y);

    for (let i = 1; i < progress - 1; i++) {
      const xc = (pixelCoords[i].x + pixelCoords[i + 1].x) / 2;
      const yc = (pixelCoords[i].y + pixelCoords[i + 1].y) / 2;
      ctx.quadraticCurveTo(pixelCoords[i].x, pixelCoords[i].y, xc, yc);
    }

    // Draw the last segment as a simple line
    if (progress >= 2 && progress < pixelCoords.length) {
      ctx.lineTo(pixelCoords[progress - 1].x, pixelCoords[progress - 1].y);
    }

    ctx.stroke();
  }

  progress++;
  if (progress <= pixelCoords.length) {
    requestAnimationFrame(draw);
  }
}

    
  function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();

    if (pixelCoords.length > 0) {
      ctx.moveTo(pixelCoords[0].x, pixelCoords[0].y);

      for (let i = 1; i < progress; i++) {
        ctx.lineTo(pixelCoords[i].x, pixelCoords[i].y);
      }
      ctx.stroke();
    }

    progress++;
    if (progress <= totalPoints) {
      requestAnimationFrame(draw);
    }
  }

 draw2();
}




 
export default {
  name: "TravelBlogEntry",
  data() {
      return {
	  title: "Dublin",
	  date: "Spring 2025",
      entries: [
        {
          id: 1,
          title: "Dublin",
          content:
            "I was looking for a extended weekend break somewhere outside of Schengen in order to spare my visa. Dublin came on the radar and is a relatively short flight from the east coast. I won't lie this trip was inspired more about a desire to get out of Arlington then anything else. That being said, I really enjoyed my time in Dublin.",
          image: "/blog-images/dublin/1.jpg",
        },
        {
          id: 2,
          title: "Drinkers Paradise",
          content:
            "There are many places where you have to go looking for the party. Dublin is not one of them. There are cozy fun pubs as far as the eye can see. I was also taken aback with just how friendly everyone was. So warm, funny and inviting. There is a there there, with a lot of music, a distinct culture and art. It is a vibe as the kids say. I think there are things to explore here. If anything it is great layover spot. Easy to get in and out of and a good way to break up an atlantic flight.",
          image: "/blog-images/dublin/2.jpg",
        },
        {
          id: 3,
          title: "Getting outside of the city",
          content:
            "The city is really fun but small. Like I said great for a few days to hang, have some beers, pick up some books from the awesome amount of book stores. Like many places though it is good to get out of the city for a bit. Howth is a beautiful place about 30 minutes outside of the city. You can take a bus or a train to get there. There are the cliffs which I would like to explore some more and and cool harbor where you can hang and have some good food and drinks. Dublin was exactly what I was looking for in a short trip, so would highly recommend.",
          image: "/blog-images/dublin/3.jpg",
        },
      ],
    };
  },
    mounted() {
	console.log("MOUNTED")
	//animateCity();
	}
};
</script>

<style scoped>
.page-wrapper {
  max-width: 960px;
  margin: 0 auto;
  padding: 2rem;
}

.page-header {
  margin-bottom: 2rem;
  text-align: center;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.logo {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.blog-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.entry {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 8px;
}

/* Entry with image on the left */
.entry--left .entry__image {
  order: 0;
}
.entry--left .entry__content {
  order: 1;
  text-align: left;
}

/* Entry with image on the right */
.entry--right .entry__image {
  order: 1;
}
.entry--right .entry__content {
  order: 0;
  text-align: right;
}

.entry__image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
}

.entry__content {
  flex: 1;
}

h2 {
    margin-top: 0;
  font-weight: bold;
  text-align: center;    
}

/* Responsive: stack elements on smaller screens */
@media (max-width: 768px) {
  .entry {
    flex-direction: column;
    text-align: center;
  }
  .entry__content {
    order: 0;
  }
}
</style>
